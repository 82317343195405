import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {CategoryService} from '../../../../../shared/services/admin/category.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  GetAdvertiserInterface,
  GetCategoryInterface,
  GetFaqInterface,
  EditFaqInterface,
  GetTagInterface
} from '@d-aim/common';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FaqService} from '../../../../../shared/services/admin/faq.service';
import {AdvertiserService} from '../../../../../shared/services/admin/advertiser.service';
import {TagService} from '../../../../../shared/services/admin/tag.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-faq-edit',
  templateUrl: './faq-edit.component.html',
  styleUrls: ['./faq-edit.component.scss']
})
export class FaqEditComponent implements OnInit {
  advertisers: GetAdvertiserInterface[] = [];
  tags: GetTagInterface[] = [];
  categories: GetCategoryInterface[] = [];
  loading = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly categoryService: CategoryService,
    private readonly tagService: TagService,
    private readonly advertiserService: AdvertiserService,
    private readonly faqService: FaqService,
    private readonly dialogRef: MatDialogRef<FaqEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { entity?: GetFaqInterface },
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  get id(): number | undefined {
    return this.data?.entity?.id;
  }

  hasError = false;
  form = this.fb.group({
    order: [1],
    draft: [true],
    category: [null],
    advertisers: [],
    tags: [],
    questionEn: [''],
    questionFr: [''],
    answerEn: [''],
    answerFr: [''],
  });

  model: Record<string, any> = {
    order: 1,
    draft: true,
    category: null,
    advertisers: [],
    tags: [],
    questionEn: '',
    questionFr: '',
    answerEn: '',
    answerFr: '',
  };

  fields: FormlyFieldConfig[] = [];

  async fetchData(): Promise<void> {
    const [advertisers, categories, tags] = await Promise.all([
      this.advertiserService.findAll(),
      this.categoryService.findAll(),
      this.tagService.findAll(),
    ]);
    this.advertisers = advertisers;
    this.categories = categories;
    this.tags = tags;
  }

  async ngOnInit(): Promise<void> {
    await this.fetchData();

    this.fields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-4',
            key: 'category',
            type: 'select',
            templateOptions: {
              options: [
                { label: this.translationService.t('translation:admin.faq.category-filter.without'), id: 0 },
                ...this.categories.map(e => ({ label: e.labelFr, value: e.id }))
              ],
              label: this.translationService.t('translation:admin.faq.category'),
            }
          },
          {
            className: 'col-4',
            key: 'advertisers',
            type: 'multi-select',
            templateOptions: {
              multiple: true,
              options: this.advertisers.map(e => ({ label: e.label, value: e.id })),
              label: this.translationService.t('translation:admin.faq.advertisers'),
            }
          },
          {
            className: 'col-4',
            key: 'tags',
            type: 'multi-select-autocomplete-tag',
            templateOptions: {
              multiple: true,
              options: this.tags.map(e => e.label),
              label: this.translationService.t('translation:admin.faq.tags'),
            }
          }
        ]
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            key: 'draft',
            type: 'toggle',
            templateOptions: {
              label: this.translationService.t('translation:admin.faq.draft'),
            }
          },
        ]
      },
      {
        wrappers: ['tabs'],
        fieldGroup: [
          {
            templateOptions: { label: this.translationService.t('translation:admin.faq.french') },
            fieldGroup: [
              {
                key: 'questionFr',
                type: 'input',
                templateOptions: {
                  label: this.translationService.t('translation:admin.faq.question'),
                  required: true,
                }
              },
              {
                key: 'answerFr',
                type: 'ckeditor',
                templateOptions: {
                  required: true,
                }
              }
            ],
          },
          {
            templateOptions: { label: this.translationService.t('translation:admin.faq.english') },
            fieldGroup: [
              {
                key: 'questionEn',
                type: 'input',
                templateOptions: {
                  label: this.translationService.t('translation:admin.faq.question'),
                  required: true,
                }
              },
              {
                key: 'answerEn',
                type: 'ckeditor',
                templateOptions: {
                  required: true,
                }
              },
            ],
          },
        ],
      },
    ];

    if (this.data?.entity) {
      this.model = {
        order: this.data.entity.order,
        questionEn: this.data.entity.questionEn,
        questionFr: this.data.entity.questionFr,
        answerFr: this.data.entity.answerFr,
        answerEn: this.data.entity.answerEn,
        draft: this.data.entity.draft,
        category: this.data.entity.category?.id,
        advertisers: this.data.entity.advertisers?.map(el => ({ label: el.label, value: el.id })),
        tags: this.data.entity.tags.map(e => e.label),
      };
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    const data: EditFaqInterface = {
      order: this.data?.entity?.order || 1,
      questionEn: this.form.get('questionEn')?.value,
      questionFr: this.form.get('questionFr')?.value,
      answerFr: this.form.get('answerFr')?.value,
      answerEn: this.form.get('answerEn')?.value,
      draft: this.form.get('draft')?.value,
      categoryId: this.form.get('category')?.value || null,
      advertisersIds: this.form.get('advertisers')?.value.map((e: { value: number }) => e.value),
      tags: this.form.get('tags')?.value,
    };

    try {
      this.hasError = false;
      if (this.id) {
        await this.faqService.update(this.id, data);
      } else {
        await this.faqService.create(data);
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
    }
  }

}
