import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import {UserRoutingModule} from './user.routing-module';
import { UserListComponent } from './user-list/user-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {SharedModule} from '../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {I18NextModule} from 'angular-i18next';

@NgModule({
  declarations: [UserComponent, EditUserComponent, UserListComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    SharedModule,
    ReactiveFormsModule,
    FormlyModule,
    MatSlideToggleModule,
    I18NextModule,
  ]
})
export class UserModule { }
