import {Component, Inject, OnInit} from '@angular/core';
import {DemoService} from '../../../../shared/services/admin/demo.service';
import { GetDemoInterface} from '@d-aim/common';
import {MatDialog} from '@angular/material/dialog';
import {DemoEditComponent} from '../demo-edit/demo-edit.component';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import {SettingService} from '../../../../shared/services/setting.service';

@Component({
  selector: 'app-demo-list',
  templateUrl: './demo-list.component.html',
  styleUrls: ['./demo-list.component.scss']
})
export class DemoListComponent implements OnInit {
  dataSource: GetDemoInterface[] = [];
  displayedColumns = ['status', 'id', 'label', 'creator', 'createdAt', 'redirectTo', 'actions'];
  limit = 0;

  constructor(
    private readonly dialog: MatDialog,
    private readonly demoService: DemoService,
    private readonly settingService: SettingService,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    const [DEMO_MAX_ENV, data] = await Promise.all([
      this.settingService.findOneByKey('DEMO_MAX_ENV'),
      this.demoService.findAll(),
    ]);

    this.limit = Number(DEMO_MAX_ENV.value);
    this.dataSource = data;
  }

  async upsert(entity?: GetDemoInterface): Promise<void> {
    const dialogRef = this.dialog.open(DemoEditComponent, { width: '720px', data: { entity } });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchData();
      }
    });
  }

  async delete(entity: GetDemoInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.demo.delete', { name: entity.label }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.demoService.deleteOne(entity.id);
        await this.fetchData();
      }
    });
  }

  async refresh(entity: GetDemoInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.demo.refresh', { name: entity.label }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.demoService.refresh(entity.id);
        await this.fetchData();
      }
    });
  }
}
