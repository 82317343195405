import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {GetCategoryInterface, GetFaqInterface} from '@d-aim/common';
import {CategoryService} from '../../../../../shared/services/admin/category.service';
import {CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {MatDialog} from '@angular/material/dialog';
import {CategoryEditComponent} from '../category-edit/category-edit.component';
import {ConfirmDialogComponent} from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {MatTable} from '@angular/material/table';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  @ViewChild('table')
  table!: MatTable<GetFaqInterface>;

  data: GetCategoryInterface[] = [];
  displayedColumns = ['drag', 'label', 'icon', 'actions'];

  constructor(
    private readonly categoryService: CategoryService,
    private readonly dialog: MatDialog,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.data = await this.categoryService.findAll();
    this.rerenderTable();
  }

  async drop($event: CdkDragDrop<GetCategoryInterface[]>): Promise<void> {
    if ($event.previousIndex === $event.currentIndex) {
      return;
    }
    moveItemInArray(this.data, $event.previousIndex, $event.currentIndex);
    this.rerenderTable();
    await this.saveOrder();
  }

  async saveOrder(): Promise<void> {
    this.data = await this.categoryService.reorder({ ids: this.data.map(e => e.id) });
  }

  async addCategory(category?: GetCategoryInterface): Promise<void> {
    const dialogRef = this.dialog.open(CategoryEditComponent, { width: '720px', data: { category } });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchData();
      }
    });
  }

  async updateCategory(category: GetCategoryInterface): Promise<void> {
    await this.addCategory(category);
  }

  async delete(category: GetCategoryInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.category.delete', { name: category.labelFr }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.categoryService.deleteOne(category.id);
        await this.fetchData();
      }
    });
  }

  rerenderTable(): void {
    this.table.renderRows();
  }
}
