<div class="row">
  <mat-form-field appearance="fill">
    <mat-label>
      {{ 'translation:formly.icon-selector.icon' | i18nextEager }}
    </mat-label>
    <input matInput [matAutocomplete]="auto" [formControl]="iconFormControl">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let icon of filteredIcons | async" [value]="icon">
        <mat-icon [attr.data-content]="icon">{{ icon }}</mat-icon>
        <span>{{icon}}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-icon matPrefix [attr.data-content]="iconFormControl.value">{{ iconFormControl.value }}</mat-icon>
    <button mat-icon-button matSuffix *ngIf="iconFormControl.value" (click)="iconFormControl.setValue('')">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-form-field>
</div>

