import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { UserListDataSource } from './user-list-datasource';
import {GetUserInterface} from '@d-aim/common';
import {UserService} from '../../../shared/services/user/user.service';
import {MatDialog} from '@angular/material/dialog';
import {EditUserComponent} from '../edit-user/edit-user.component';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {CurrentUserService} from '../../../shared/authentication/services/current-user.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<GetUserInterface>;
  dataSource: UserListDataSource = new UserListDataSource();
  displayedColumns = ['email', 'username', 'firstName', 'lastName', 'enabled', 'actions'];

  constructor(
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    public readonly currentUserService: CurrentUserService,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    await this.fetchUsers();
  }

  async fetchUsers(): Promise<void> {
    this.dataSource = new UserListDataSource();
    this.dataSource.data = await this.userService.findAll();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  async createNewUser(): Promise<void> {
    const dialogRef = this.dialog.open(EditUserComponent, { width: '720px' });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchUsers();
      }
    });
  }

  async updateUser(user: GetUserInterface): Promise<void> {
    const dialogRef = this.dialog.open(EditUserComponent, { width: '720px', data: { user }});
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchUsers();
      }
    });
  }

  async deleteUser(user: GetUserInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:user.delete', { username: user.username }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.userService.deleteUser(user.id);
        await this.fetchUsers();
      }
    });
  }
}
