import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EditCategoryInterface, GetCategoryInterface, GetCategoryNestedInterface, ReorderInterface} from '@d-aim/common';
import {CrudGenericService} from '../crud-generic.service';
import {MultiSelectGroupOption} from '../../components/formly/multi-select/multi-select.component';

export type FindAllNestedFiler = { search?: string; categoryId?: number; draftOnly?: boolean; advertiserId?: number };

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends CrudGenericService<
  GetCategoryInterface,
  EditCategoryInterface,
  EditCategoryInterface,
  GetCategoryInterface
>{
  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient, 'category');
  }

  async reorder(dto: ReorderInterface): Promise<GetCategoryInterface[]> {
    return this.httpClient.put<GetCategoryInterface[]>(`${this.urlPrefix}/reorder`, dto).toPromise();
  }

  async findAllNested(filter?: FindAllNestedFiler): Promise<GetCategoryNestedInterface[]> {
    let params = new HttpParams();
    if (filter?.search) {
      params = params.set('search', filter.search);
    }

    if (filter?.categoryId) {
      params = params.set('categoryId', filter.categoryId.toString());
    }

    if (filter?.draftOnly) {
      params = params.set('draftOnly', 'true');
    }

    if (filter?.advertiserId) {
      params = params.set('advertiserId', filter.advertiserId.toString());
    }

    return this.httpClient.get<GetCategoryNestedInterface[]>(`${this.urlPrefix}/nested`, { params }).toPromise();
  }

  async findAllNestedAsMultiSelectOptionGroup(filter?: FindAllNestedFiler): Promise<MultiSelectGroupOption[]> {
    const categories = await this.findAllNested(filter);
    const groupOptions: MultiSelectGroupOption[] = [];
    for (const cat of categories) {
      const group: MultiSelectGroupOption = { label: cat.id === null ? 'N/A' : cat.labelFr, disabled: false, options: [] };
      group.options = cat.faqs.map(e => ({ label: e.questionFr, value: e.id }));
      groupOptions.push(group);
    }
    return groupOptions;
  }
}
