import { Injectable } from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import {ResourceLanguage} from 'i18next';

@Injectable({
  providedIn: 'root'
})
export class TranslationsLoaderService {
  private readonly httpClient: HttpClient;
  constructor(
    private readonly handler: HttpBackend,
  ) {
    this.httpClient = new HttpClient(handler);
  }

  getTranslations(lng: string): Promise<ResourceLanguage> {
    return this.httpClient.get<ResourceLanguage>(`/assets/locale/${lng}.json?_=${Date.now()}`).toPromise();
  }
}
