import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntrypointComponent } from './entrypoint.component';
import { EntrypointListComponent } from './entrypoint-list/entrypoint-list.component';
import { EntrypointEditComponent } from './entrypoint-edit/entrypoint-edit.component';
import {SharedModule} from '../../../shared/shared.module';
import {MatTableModule} from '@angular/material/table';
import {FormlyModule} from '@ngx-formly/core';
import {ReactiveFormsModule} from '@angular/forms';
import {I18NextModule} from 'angular-i18next';

@NgModule({
  declarations: [EntrypointComponent, EntrypointListComponent, EntrypointEditComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTableModule,
    FormlyModule,
    ReactiveFormsModule,
    I18NextModule
  ]
})
export class EntrypointModule { }
