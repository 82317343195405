import {Component, OnInit} from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss']
})
export class IconSelectorComponent extends FieldType implements OnInit {
  filteredIcons!: Observable<string[]>;
  icons: string[] = [];

  get iconFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  ngOnInit(): void {
    this.icons = this.to.options as string[];
    this.filteredIcons = this.iconFormControl
      .valueChanges
      .pipe(startWith(''), map(icon => icon ? this.filterIcon(icon) : this.icons.slice()));
  }

  private filterIcon(value: string): string[] {
    return this.icons.filter(icon => icon.toLowerCase().includes(value.toLowerCase()));
  }
}
