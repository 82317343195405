<div #detailProfile>
  <app-badge-user *ngIf="userInfo" [size]="34" [element]="userInfo" [showTooltip]="false" (click)="openMenu = !openMenu"></app-badge-user>
  <mat-card class="detail-profile-container" *ngIf="openMenu">
    <mat-list class="detail-list">
      <a [href]="profileLink" target="_blank">
        <mat-list-item>
          <mat-icon matListIcon>account_circle</mat-icon>
          <p matLine>
            {{ 'translation:my-profile.my-infos' | i18nextEager }}
          </p>
        </mat-list-item>
      </a>
      <mat-list-item (click)="disconnect()" class="clickable">
        <mat-icon matListIcon >logout</mat-icon>
        <p matLine>
          {{ 'translation:logout' | i18nextEager }}
        </p>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>

