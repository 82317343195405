<div class="header">
  <h2 mat-dialog-title>
    <ng-container *ngIf="!id">
      {{ 'translation:admin.entrypoint.create' | i18nextEager }}
    </ng-container>
    <ng-container *ngIf="id">
      {{ 'translation:admin.entrypoint.update' | i18nextEager }}
    </ng-container>
  </h2>
  <div class="flex-space"></div>
  <button mat-icon-button [mat-dialog-close] class="button-close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<ng-container *ngIf="!loading">
  <mat-dialog-content>
    <div class="alert alert-danger" *ngIf="hasError">
    <span>
      {{ 'error:generic' | i18nextEager }}
    </span>
    </div>
    <form [formGroup]="form">
      <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ 'translation:action.cancel' | i18nextEager }}
    </button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="form.invalid">
      {{ 'translation:action.save' | i18nextEager }}
    </button>
  </mat-dialog-actions>
</ng-container>
<app-loader *ngIf="loading"></app-loader>
