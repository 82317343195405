import {Inject, Injectable} from '@angular/core';
import {CKEditor4} from 'ckeditor4-angular';
import {CurrentUserService} from '../../../authentication/services/current-user.service';
import {APP_CONFIG, AppConfig} from '../../../../app.config';
import {AuthInterceptor} from '../../../authentication/interceptors/auth.interceptor';

@Injectable({
  providedIn: 'root'
})
export class CkeditorService {
  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    private readonly userService: CurrentUserService,
  ) { }

  handleCkeditorInit(event: CKEditor4.EventInfo): void {
    (window as any).CKEDITOR.config.extraPlugins = 'uploadimage';
    (window as any).CKEDITOR.config.uploadUrl = AuthInterceptor.createURL(this.appConfig.apiBackendUrl, 'upload/file');
  }

  handleCkeditorFileUpload(event: CKEditor4.EventInfo): void {
    const xhr: XMLHttpRequest = event.data.fileLoader.xhr;
    xhr.setRequestHeader('Authorization', this.userService.getAccessTokenForHeader());
    xhr.withCredentials = true;
  }
}
