<div class="header">
  <h2 mat-dialog-title>{{ 'translation:action.confirm' | i18nextEager }}</h2>
  <div class="flex-space"></div>
  <button mat-icon-button [mat-dialog-close]="false" class="button-close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="alert alert-primary">
    {{ data.message }}
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">{{ 'translation:action.cancel' | i18nextEager }}</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true">{{ 'translation:action.confirm' | i18nextEager }}</button>
</mat-dialog-actions>
