<button mat-flat-button color="primary" class="mb-2" (click)="addCategory()">
  <mat-icon>person_add</mat-icon>
  {{ 'translation:admin.category.add' | i18nextEager }}
</button>

<div class="content mat-elevation-z2">
  <mat-table #table
             [dataSource]="data"
             cdkDropList
             (cdkDropListDropped)="drop($event)"
             [cdkDropListData]="data">
    <ng-container matColumnDef="drag">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="material-icons drag">
          reorder
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.category.label' | i18nextEager }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.labelFr || 'N/A' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="icon">
      <mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.category.icon' | i18nextEager }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.icon" [attr.data-content]="element.icon">{{element.icon}}</mat-icon> <ng-container *ngIf="!element.icon">N/A</ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="updateCategory(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;"
             cdkDrag
             [cdkDragData]="row"
             cdkDragLockAxis="y">
    </mat-row>
  </mat-table>
</div>

