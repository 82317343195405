import { Component } from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {FormControl} from '@angular/forms';

export type MultiSelectOption = { label: (string|number|null); value: (string|number|null) };
export type MultiSelectGroupOption = { label: string, options: MultiSelectOption[], disabled: boolean };

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent extends FieldType {
  get multiSelectFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  get values(): string {
    return this.formControl.value?.map((e: { label: string }) => e.label).join(', ');
  }
  get fieldGroupOptions(): MultiSelectGroupOption[]|null {
    return this.to.groupOptions as MultiSelectGroupOption[] || null;
  }
  get fieldOptions(): MultiSelectOption[] {
    return this.to.options as MultiSelectOption[];
  }

  compareWith(a: { value: string }, b: { value: string }): boolean {
    return (a === null && b === null) || a?.value === b?.value;
  }
}
