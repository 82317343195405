import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  EditAdvertiserInterface,
  GetAdvertiserInterface, GetFaqInterface,
} from '@d-aim/common';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {AdvertiserService} from '../../../../shared/services/admin/advertiser.service';
import {CategoryService} from '../../../../shared/services/admin/category.service';
import {MultiSelectGroupOption, MultiSelectOption} from '../../../../shared/components/formly/multi-select/multi-select.component';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-advertiser-edit',
  templateUrl: './advertiser-edit.component.html',
  styleUrls: ['./advertiser-edit.component.scss']
})
export class AdvertiserEditComponent implements OnInit {
  loading = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly advertiserService: AdvertiserService,
    private readonly categoryService: CategoryService,
    private readonly dialogRef: MatDialogRef<AdvertiserEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { entity?: GetAdvertiserInterface },
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  get id(): number | undefined {
    return this.data?.entity?.id;
  }

  hasError = false;
  form = this.fb.group({
    label: [''],
    faqsIds: [[]],
    advertiserId: [null],
  });

  model: Record<string, any> = {
    label: '',
    faqsIds: [],
    advertiserId: null,
  };

  fields: FormlyFieldConfig[] = [];

  async ngOnInit(): Promise<void> {
    const advertisers = (await this.advertiserService.findAll())
                          .filter(e => e.id !== this.id)
                          .map(e => ({ label: e.label, value: e.id }));
    const groupOptions: MultiSelectGroupOption[] = await this.categoryService.findAllNestedAsMultiSelectOptionGroup();
    const defaultValue = this.id ? await this.getSelectedOptionsFromAdvertiser(this.id) : [];

    const fieldAdvertiserModel: FormlyFieldConfig = {
      key: 'advertiserId',
      type: 'select',
      templateOptions: {
        label: this.translationService.t('translation:admin.advertiser.use-same-faq'),
        options: advertisers,
      }
    };

    this.form.get('advertiserId')?.valueChanges.subscribe(async (id) => {
      if (id !== null) {
        this.form.get('faqsIds')?.setValue(await this.getSelectedOptionsFromAdvertiser(id));
      }
    });

    this.fields = [
      {
        key: 'label',
        type: 'input',
        templateOptions: {
          attributes: {
            autocomplete: 'off',
          },
          label: this.translationService.t('translation:admin.advertiser.label'),
          required: true,
        }
      },
      fieldAdvertiserModel,
      {
        key: 'faqsIds',
        type: 'multi-select',
        templateOptions: {
          label: this.translationService.t('translation:admin.advertiser.faqs'),
          multiple: true,
          groupOptions,
        }
      }
    ];

    if (this.data?.entity) {
      this.model = {
        label: this.data.entity.label,
        faqsIds: defaultValue,
        advertiserId: null,
      };
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    const data: EditAdvertiserInterface = {
      label: this.form.get('label')?.value,
      faqsIds: this.form.get('faqsIds')?.value.map((e: MultiSelectOption) => e.value),
    };

    try {
      this.hasError = false;
      if (this.id) {
        await this.advertiserService.update(this.id, data);
      } else {
        await this.advertiserService.create(data);
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
    }
  }

  async getSelectedOptionsFromAdvertiser(advertiserId: number): Promise<MultiSelectOption[]> {
    const defaultValue: MultiSelectOption[] = [];
    const selectedCategories = await this.categoryService.findAllNested({ advertiserId });
    selectedCategories.forEach((cat) => {
      cat.faqs.forEach((faq) => {
        defaultValue.push({ label: faq.questionFr, value: faq.id });
      });
    });

    return defaultValue;
  }
}
