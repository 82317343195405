<button mat-flat-button color="primary" class="mb-2" (click)="createNewUser()">
  <mat-icon>person_add</mat-icon>
  {{ 'translation:user.add-new-user' | i18nextEager }}
</button>

<div class="mat-elevation-z2">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'translation:user.email' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.email}}</td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'translation:user.username' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.username}}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'translation:user.first-name' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.firstName}}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'translation:user.last-name' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:user.enabled' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-icon>{{row.enabled ? 'done' : 'close' }}</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="updateUser(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteUser(row)" [disabled]="currentUserService.getUsername() === row.username">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.data?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>
