import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  EditEntrypointInterface,
  GetEntrypointInterface
} from '@d-aim/common';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {EntrypointService} from '../../../../shared/services/admin/entrypoint.service';
import {FaqService} from '../../../../shared/services/admin/faq.service';
import {
  MultiSelectGroupOption,
  MultiSelectOption
} from '../../../../shared/components/formly/multi-select/multi-select.component';
import {CategoryService} from '../../../../shared/services/admin/category.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-entrypoint-edit',
  templateUrl: './entrypoint-edit.component.html',
  styleUrls: ['./entrypoint-edit.component.scss']
})
export class EntrypointEditComponent implements OnInit {
  loading = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly entrypointService: EntrypointService,
    private readonly faqService: FaqService,
    private readonly categoryService: CategoryService,
    private readonly dialogRef: MatDialogRef<EntrypointEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { entity?: GetEntrypointInterface },
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  get id(): number | undefined {
    return this.data?.entity?.id;
  }

  hasError = false;
  form = this.fb.group({
    label: [''],
    code: [''],
    faqs: [[]],
  });

  model: Record<string, any> = {
    label: '',
    code: '',
    faqs: [],
  };

  fields: FormlyFieldConfig[] = [];

  async ngOnInit(): Promise<void> {
    const groupOptions: MultiSelectGroupOption[] = await this.categoryService.findAllNestedAsMultiSelectOptionGroup();
    const defaultValue: MultiSelectOption[] = [];
    if (this.id) {
      const selectedCategories = await this.categoryService.findAllNested({ advertiserId: this.id });
      selectedCategories.forEach((cat) => {
        cat.faqs.forEach((faq) => {
          defaultValue.push({ label: faq.questionFr, value: faq.id });
        });
      });
    }

    this.fields = [
      {
        key: 'label',
        type: 'input',
        templateOptions: {
          attributes: {
            autocomplete: 'off',
          },
          label: this.translationService.t('translation:admin.entrypoint.label'),
          required: true,
        }
      },
      {
        key: 'code',
        type: 'input',
        templateOptions: {
          attributes: {
            autocomplete: 'off',
          },
          label: this.translationService.t('translation:admin.entrypoint.code'),
          required: true,
        }
      },
      {
        key: 'faqs',
        type: 'multi-select',
        templateOptions: {
          multiple: true,
          groupOptions,
          label: this.translationService.t('translation:admin.entrypoint.faqs'),
        }
      },
    ];

    if (this.data?.entity) {
      this.model = {
        label: this.data.entity.label,
        code: this.data.entity.code,
        faqs: this.data.entity.faqs.map(e => ({ label: e.questionFr, value: e.id })),
      };
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    const data: EditEntrypointInterface = {
      label: this.form.get('label')?.value,
      code: this.form.get('code')?.value,
      faqsIds: this.form.get('faqs')?.value?.map((e: { value: number }) => e.value),
    };

    try {
      this.hasError = false;
      if (this.id) {
        await this.entrypointService.update(this.id, data);
      } else {
        await this.entrypointService.create(data);
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
    }
  }
}
