<button mat-flat-button color="primary" class="mb-2" (click)="create()">
  <mat-icon>person_add</mat-icon>
  {{ 'translation:admin.entrypoint.add' | i18nextEager }}
</button>

<div class="mat-elevation-z2">
  <table mat-table class="full-width-table" [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.entrypoint.label' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.label}}</td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.entrypoint.code' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="update(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
