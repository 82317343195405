import { Injectable } from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {CrudGenericService} from '../crud-generic.service';
import {
  EditFaqInterface,
  GetCategoryNestedInterface,
  GetFaqInterface,
  ReorderFaqInterface, ReorderFaqItemInterface,
  ReorderInterface
} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private httpClient: HttpClient;

  constructor(
    private readonly httpHandler: HttpBackend,
  ) {
    this.httpClient = new HttpClient(this.httpHandler);
  }

  async fetchAll(): Promise<string[]> {
    return this.httpClient.get<string[]>('/assets/icons.json').toPromise();
  }
}
