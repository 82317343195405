import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PingService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  async ping(): Promise<{ status: string }> {
    return this.httpClient.get<{ status: string }>('/').toPromise();
  }
}
