import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EditGroupInterface, GetClientRolesInterface, GetGroupInterface} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  findAll(): Promise<GetGroupInterface[]> {
    return this.httpClient.get<GetGroupInterface[]>('group').toPromise();
  }

  addGroup(dto: EditGroupInterface): Promise<GetGroupInterface> {
    return this.httpClient.post<GetGroupInterface>('group', dto).toPromise();
  }

  updateGroup(id: string, dto: EditGroupInterface): Promise<GetGroupInterface> {
    return this.httpClient.put<GetGroupInterface>(`group/${id}`, dto).toPromise();
  }

  async deleteGroup(id: string): Promise<void> {
    await this.httpClient.delete(`group/${id}`).toPromise();
  }

  async groupApplicationRoles(id: string): Promise<GetClientRolesInterface[]> {
    return this.httpClient.get<GetClientRolesInterface[]>(`group/${id}/roles`).toPromise();
  }
}
