import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { GroupListDatasource } from './group-list-datasource';
import {GetGroupInterface} from '@d-aim/common';
import {MatDialog} from '@angular/material/dialog';
import {EditGroupComponent} from '../edit-group/edit-group.component';
import {ConfirmDialogComponent} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {GroupService} from '../../../shared/services/group/group.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<GetGroupInterface>;
  dataSource: GroupListDatasource = new GroupListDatasource();
  displayedColumns = ['name', 'actions'];

  constructor(
    private readonly groupService: GroupService,
    private readonly dialog: MatDialog,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    await this.fetchGroups();
  }

  async fetchGroups(): Promise<void> {
    this.dataSource = new GroupListDatasource();
    this.dataSource.data = await this.groupService.findAll();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  async createNewGroup(): Promise<void> {
    const dialogRef = this.dialog.open(EditGroupComponent, { width: '720px' });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchGroups();
      }
    });
  }

  async updateGroup(group: GetGroupInterface): Promise<void> {
    const dialogRef = this.dialog.open(EditGroupComponent, { width: '720px', data: { group }});
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchGroups();
      }
    });
  }

  async deleteGroup(group: GetGroupInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:group.delete', { name: group.name }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.groupService.deleteGroup(group.id);
        await this.fetchGroups();
      }
    });
  }
}
