import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {GetAdvertiserInterface} from '@d-aim/common';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {AdvertiserService} from '../../../../shared/services/admin/advertiser.service';
import {AdvertiserEditComponent} from '../advertiser-edit/advertiser-edit.component';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-advertiser-list',
  templateUrl: './advertiser-list.component.html',
  styleUrls: ['./advertiser-list.component.scss']
})
export class AdvertiserListComponent implements OnInit {
  dataSource: GetAdvertiserInterface[] = [];
  displayedColumns = ['id', 'label', 'secret', 'actions'];

  constructor(
    private readonly dialog: MatDialog,
    private readonly advertiserService: AdvertiserService,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchData();
  }
  async fetchData(): Promise<void> {
    this.dataSource = await this.advertiserService.findAll();
  }

  async create(entity?: GetAdvertiserInterface): Promise<void> {
    const dialogRef = this.dialog.open(AdvertiserEditComponent, { width: '720px', data: { entity } });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchData();
      }
    });
  }

  async update(entity: GetAdvertiserInterface): Promise<void> {
    await this.create(entity);
  }

  async delete(entity: GetAdvertiserInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.advertiser.delete', { name: entity.label }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.advertiserService.deleteOne(entity.id);
        await this.fetchData();
      }
    });
  }

  async refreshSecret(entity: GetAdvertiserInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.advertiser.renew', { name: entity.label }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        const updatedEntity = await this.advertiserService.refreshSecret(entity.id);
        entity.secret = updatedEntity.secret;
      }
    });
  }
}
