<div class="faq-header">
  <button mat-flat-button color="primary" class="mb-2" (click)="addFaq()">
    <mat-icon>person_add</mat-icon>
    {{ 'translation:admin.faq.add' | i18nextEager }}
  </button>
  <div [formGroup]="form" class="faq-search">
    <mat-form-field class="search" appearance="fill">
      <mat-label>
        {{ 'translation:admin.faq.search' | i18nextEager }}
      </mat-label>
      <input matInput [placeholder]="'translation:admin.faq.search-placeholder' | i18nextEager" formControlName="search">
    </mat-form-field>
    <mat-form-field class="category" appearance="fill">
      <mat-label>
        {{ 'translation:admin.faq.category-filter.label' | i18nextEager }}
      </mat-label>
      <mat-select formControlName="categoryId">
        <mat-option [value]="null">
          {{ 'translation:admin.faq.category-filter.all' | i18nextEager }}
        </mat-option>
        <mat-option [value]="-1">
          {{ 'translation:admin.faq.category-filter.without' | i18nextEager }}
        </mat-option>
        <mat-option *ngFor="let category of allCategories" [value]="category.id">
          {{category.labelFr}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="draftOnly">
      {{ 'translation:admin.faq.draft-only' | i18nextEager }}
    </mat-slide-toggle>
  </div>
</div>

<div class="mat-elevation-z2">
  <mat-table>
    <ng-container matColumnDef="drag">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="questionFr">
      <mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.faq.question' | i18nextEager }}
      </mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <div cdkDropListGroup class="faq-table">
    <ng-container *ngFor="let data of dataSource; let isFirst = first;">
      <mat-table [dataSource]="[data]">
        <ng-container matColumnDef="groupName">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id === null ? 'N/A' : element.labelFr }} </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['groupName'];"></mat-row>
      </mat-table>

      <mat-table #table
                 [dataSource]="data.faqs"
                 cdkDropList
                 (cdkDropListDropped)="drop($event)"
                 [cdkDropListData]="data.faqs">
        <ng-container matColumnDef="drag">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="material-icons drag" [class.disabled]="!dragNDropEnabled">
              reorder
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.id}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="questionFr">
          <mat-header-cell *matHeaderCellDef>
            {{ 'translation:admin.faq.question' | i18nextEager }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.questionFr}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="update(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="delete(element)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: displayedColumns;"
                 cdkDrag
                 [cdkDragData]="row"
                 cdkDragLockAxis="y"
                 [cdkDragDisabled]="!dragNDropEnabled">
        </mat-row>

        <ng-container matColumnDef="noRecord">
          <mat-footer-cell *matFooterCellDef>
            {{ 'translation:admin.faq.no-faq' | i18nextEager }}
          </mat-footer-cell>
        </ng-container>
        <ng-template [ngIf]="data.faqs.length === 0">
          <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
        </ng-template>

      </mat-table>
    </ng-container>
  </div>
  <mat-paginator [length]="categories.length"
                 [pageSize]="currentPageSize"
                 (page)="changePage($event)"
                 [pageSizeOptions]="[5, 20, 100]">
  </mat-paginator>
</div>

