import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from '../../shared/authentication/guards/auth.guard';
import {GroupComponent} from './group.component';
import {RolesEnum} from '@d-aim/common';

const routes: Routes = [
  {
    path: 'group',
    component: GroupComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [RolesEnum.admin_group]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupRoutingModule {}
