import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  EditCategoryInterface,
  GetCategoryInterface,
} from '@d-aim/common';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {CategoryService} from '../../../../../shared/services/admin/category.service';
import {IconService} from '../../../../../shared/services/admin/icon.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {

  loading = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly categoryService: CategoryService,
    private readonly iconService: IconService,
    private readonly dialogRef: MatDialogRef<CategoryEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { category?: GetCategoryInterface },
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  get categoryId(): number | undefined {
    return this.data?.category?.id;
  }

  hasError = false;
  form = this.fb.group({
    icon: [null],
    labelEn: [''],
    labelFr: [''],
  });

  model: Record<string, any> = {
    icon: null,
    labelEn: '',
    labelFr: '',
  };

  fields: FormlyFieldConfig[] = [];

  async ngOnInit(): Promise<void> {
    this.fields = [
      {
        key: 'icon',
        type: 'icon-select',
        templateOptions: {
          options: await this.iconService.fetchAll(),
          attributes: {
            autocomplete: 'off',
          },
          label: this.translationService.t('translation:admin.category.icon'),
        }
      },
      {
        key: 'labelEn',
        type: 'input',
        templateOptions: {
          attributes: {
            autocomplete: 'off',
          },
          label: this.translationService.t('translation:admin.category.label-english'),
          required: true,
        }
      },
      {
        key: 'labelFr',
        type: 'input',
        templateOptions: {
          attributes: {
            autocomplete: 'off',
          },
          label: this.translationService.t('translation:admin.category.label-french'),
          required: true,
        }
      },
    ];

    if (this.data?.category) {
      this.model = {
        icon: this.data.category.icon,
        labelEn: this.data.category.labelEn,
        labelFr: this.data.category.labelFr,
      };
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    const data: EditCategoryInterface = {
      order: this.data?.category?.order || 1,
      icon: this.form.get('icon')?.value,
      labelEn: this.form.get('labelEn')?.value,
      labelFr: this.form.get('labelFr')?.value,
    };

    try {
      this.hasError = false;
      if (this.categoryId) {
        await this.categoryService.update(this.categoryId, data);
      } else {
        await this.categoryService.create(data);
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
    }
  }

}
