import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudGenericService} from '../crud-generic.service';
import {
  EditEntrypointInterface,
  GetEntrypointInterface,
} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class EntrypointService extends CrudGenericService<
  GetEntrypointInterface,
  EditEntrypointInterface,
  EditEntrypointInterface,
  GetEntrypointInterface
>{
  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient, 'entrypoint');
  }
}
