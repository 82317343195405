import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudGenericService} from '../crud-generic.service';
import {
  EditTagInterface,
  GetTagInterface,
} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class TagService extends CrudGenericService<GetTagInterface, EditTagInterface, EditTagInterface, GetTagInterface>{
  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient, 'tag');
  }
}
