import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {CurrentUserService} from './shared/authentication/services/current-user.service';
import {BootstrapService} from './bootstrap.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription: Subscription = new Subscription();
  loading = true;
  error = false;
  menuCollapsed = false;

  constructor(
    private readonly userService: CurrentUserService,
    private readonly bootstrapService: BootstrapService,
    private readonly titleService: Title,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) {}

  async selectLang(lang: string): Promise<void> {
    await this.translationService.changeLanguage(lang);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.bootstrapService.readyState().subscribe(
        async ready => {
          if (ready) {
            this.loading = false;
          }
        },
        error => {
          console.log('Error', error);
          this.loading = false;
          this.error = true;
        },
      ),
    );

    this.subscription.add(
      interval(60).subscribe(async () => {
        try {
          await this.userService.refreshSession();
        } catch (e) {
          console.error('Couldn\'t generate new access token.');
        }
      }),
    );

    // Changement automatique du titre de la page
    this.subscription.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap((route: ActivatedRoute) => route.data),
        )
        .subscribe((data: { pageTitle?: string; pageCategorie?: string }) => {
          const title = [];
          const suffixe = 'D-PORTAL';
          if (data.pageCategorie) {
            title.push(data.pageCategorie);
          }
          if (data.pageTitle && data.pageTitle !== suffixe) {
            title.push(data.pageTitle);
          }
          title.push(suffixe);
          const titleText = title.join(' - ');
          this.titleService.setTitle(titleText);
        }),
    );
  }

  ngAfterViewInit(): void {
    if (localStorage.getItem('menuCollapsed') === 'false') {
      const layoutElem = document.getElementById('layout');
      if (layoutElem) {
        layoutElem.classList.remove('sidebar-collapse');
      }
    }
  }

  toggleSidebar(): void {
    const elt = document.getElementById('layout');
    if (!elt) {
      return;
    }
    elt.classList.toggle('sidebar-collapse');

    localStorage.setItem(
      'menuCollapsed',
      elt.classList.contains('sidebar-collapse') ? 'true' : 'false',
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

