import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoComponent } from './demo.component';
import { DemoListComponent } from './demo-list/demo-list.component';
import { DemoEditComponent } from './demo-edit/demo-edit.component';
import {MatDialogModule} from '@angular/material/dialog';
import {SharedModule} from '../../../shared/shared.module';
import {FormlyModule} from '@ngx-formly/core';
import {ReactiveFormsModule} from '@angular/forms';
import {I18NextModule} from 'angular-i18next';
import {MatTableModule} from '@angular/material/table';
import { DemoRedirectToComponent } from './demo-redirect-to/demo-redirect-to.component';



@NgModule({
  declarations: [DemoComponent, DemoListComponent, DemoEditComponent, DemoRedirectToComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
    FormlyModule,
    ReactiveFormsModule,
    I18NextModule,
    MatTableModule
  ]
})
export class DemoModule { }
