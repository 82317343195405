import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import {ProfileRoutingModule} from './profile.routing-module';
import {SharedModule} from '../../shared/shared.module';
import { EditProfileRolesComponent } from './edit-profile-roles/edit-profile-roles.component';
import {MatTableModule} from '@angular/material/table';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {I18NextModule} from 'angular-i18next';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [ProfileComponent, EditProfileRolesComponent],
  imports: [
    SharedModule,
    ProfileRoutingModule,
    CommonModule,
    MatTableModule,
    MatSlideToggleModule,
    MatExpansionModule,
    I18NextModule,
    MatSelectModule,
    MatTabsModule
  ]
})
export class ProfileModule { }
