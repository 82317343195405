import {Component, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FaqEditComponent} from '../faq-edit/faq-edit.component';
import {MatDialog} from '@angular/material/dialog';
import {
  GetCategoryInterface,
  GetCategoryNestedInterface,
  GetFaqInterface,
} from '@d-aim/common';
import {ConfirmDialogComponent} from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatTable} from '@angular/material/table';
import {FaqService} from '../../../../../shared/services/admin/faq.service';
import {CategoryService} from '../../../../../shared/services/admin/category.service';
import {PageEvent} from '@angular/material/paginator';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-faq-list',
  templateUrl: './faq-list.component.html',
  styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent implements OnInit {
  @ViewChildren('table')
  tables!: QueryList<MatTable<GetFaqInterface>>;

  displayedColumns = ['drag', 'id', 'questionFr', 'actions'];
  dataSource: GetCategoryNestedInterface[] = [];
  categories: GetCategoryNestedInterface[] = [];
  allCategories: GetCategoryInterface[] = [];
  currentPageSize = 5;
  currentPageIndex = 0;
  form!: FormGroup;

  get dragNDropEnabled(): boolean {
    return !this.form.get('search')?.value && !this.form.get('draftOnly')?.value;
  }

  constructor(
    private readonly faqService: FaqService,
    private readonly categoryService: CategoryService,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      search: [''],
      categoryId: [null],
      draftOnly: [false],
    });

    this.form.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe(async () => {
      await this.fetchData();
    });
    await Promise.all([
      this.fetchAllCategories(),
      this.fetchData(),
    ]);
  }

  async fetchAllCategories(): Promise<void> {
    this.allCategories = await this.categoryService.findAll();
  }

  async addFaq(faq?: GetFaqInterface): Promise<void> {
    const dialogRef = this.dialog.open(FaqEditComponent, { width: '1200px', data: { entity: faq } });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchData();
      }
    });
  }

  rerenderTable(): void {
    this.tables.forEach((t) => t.renderRows());
  }

  async fetchData(): Promise<void>{
    const filter = {
      search: this.form.get('search')?.value,
      categoryId: this.form.get('categoryId')?.value,
      draftOnly: this.form.get('draftOnly')?.value,
    };

    const data = await this.categoryService.findAllNested(filter);
    for (const category of data) {
      for (const faq of category.faqs) {
        faq.category = category;
      }
    }
    this.categories = data;

    this.dataSource = data.slice(this.currentPageIndex, this.currentPageIndex + this.currentPageSize);
    this.rerenderTable();
  }

  async drop($event: CdkDragDrop<GetFaqInterface[]>): Promise<void> {
    if ($event.previousContainer === $event.container) {
      if ($event.previousIndex === $event.currentIndex) {
        return;
      }
      moveItemInArray($event.item.data.category.faqs, $event.previousIndex, $event.currentIndex);
    } else {
      transferArrayItem($event.previousContainer.data,
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex);
    }

    this.rerenderTable();
    await this.faqService.reorder(this.dataSource);
    await this.fetchData();
  }

  async update(faq: GetFaqInterface): Promise<void> {
    await this.addFaq(faq);
  }

  async delete(faq: GetFaqInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.faq.delete', { name: faq.questionFr }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.faqService.deleteOne(faq.id);
        await this.fetchData();
      }
    });
  }

  changePage($event: PageEvent): void {
    this.currentPageIndex = $event.pageIndex * $event.pageSize;
    this.currentPageSize = $event.pageSize;
    const end = this.currentPageIndex + this.currentPageSize;
    this.dataSource = this.categories.slice(this.currentPageIndex, end);
    this.rerenderTable();
  }
}
