import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from '../../../app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
  ) {}

  static createURL(apiBackendUrl: string, uri: string): string {
    return apiBackendUrl + (uri.startsWith('/') ? '' : '/') + uri;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const secureReq = request.clone({
      url: AuthInterceptor.createURL(this.appConfig.apiBackendUrl, request.url),
    });
    return next.handle(secureReq);
  }
}
