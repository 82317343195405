import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {APP_CONFIG} from './app/app.config';

if (environment.production) {
  enableProdMode();
}

export interface Config {
  apiBackendUrl: string;
  keycloakUrl: string;
  keycloakRealm: string;
  keycloakClientId: string;
  featureAdmin: boolean;
}

const fetchConfig = (): Promise<Config> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.open('GET', '/assets/config.json?_=' + Date.now(), true);
    // via Cache-Control header:
    xhr.setRequestHeader('Cache-Control', 'no-cache, no-store, max-age=0');

    // fallbacks for IE and older browsers:
    xhr.setRequestHeader('Expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
    xhr.setRequestHeader('Pragma', 'no-cache');
    xhr.onload = (e) => {
      resolve(xhr.response);
    };
    xhr.onerror = () => {
      reject(undefined);
    };
    xhr.send();
  });
};

fetchConfig().then((config: Config) => {
  platformBrowserDynamic([
    { provide: APP_CONFIG, useValue: config }
  ]).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});


