import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EditUserInterface, GetClientRolesInterface, GetGroupInterface, GetUserInterface} from '@d-aim/common';
import {APP_CONFIG, AppConfig} from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
  ) { }

  findAll(): Promise<GetUserInterface[]> {
    return this.httpClient.get<GetUserInterface[]>('user').toPromise();
  }

  addUser(dto: EditUserInterface): Promise<GetUserInterface> {
    return this.httpClient.post<GetUserInterface>('user', dto).toPromise();
  }

  updateUser(id: string, dto: EditUserInterface): Promise<GetUserInterface> {
    return this.httpClient.put<GetUserInterface>(`user/${id}`, dto).toPromise();
  }

  async deleteUser(id: string): Promise<void> {
    await this.httpClient.delete(`user/${id}`).toPromise();
  }

  async userApplicationRoles(id: string): Promise<GetClientRolesInterface[]> {
    return this.httpClient.get<GetClientRolesInterface[]>(`user/${id}/roles`).toPromise();
  }

  async userGroups(id: string): Promise<GetGroupInterface[]> {
    return this.httpClient.get<GetGroupInterface[]>(`user/${id}/groups`).toPromise();
  }

  getEditProfileUrl(): string {
    return `${this.appConfig.keycloakUrl}/realms/${this.appConfig.keycloakRealm}/account/#/personal-info`;
  }
}
