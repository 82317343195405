import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  // titre de la page
  title = '';
  // catégorie de la page
  categorie = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // premier chargement
    if (this.router.navigated) {
      this.setPageTitle();
    }
    // A la fin de chaque navigation
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        // on set le titre de la page à partir des data de la route
        this.setPageTitle();
      });
  }

  /**
   * Méthode permettant de récupérer le titre de la page en cours
   */
  setPageTitle(): void {
    // on remet le titre et la catégorie à vide
    this.title = '';
    this.categorie = '';
    // on récupère la route active
    let currentRoute = this.activatedRoute;
    // on va jusqu'au dernier noeud de la route active pour récupérer la route courante
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    // on récupère le titre et la catégorie de la page dans les data de la route
    if (currentRoute.data) {
      currentRoute.data.subscribe(datas => {
        if (datas.pageTitle) {
          this.title = datas.pageTitle;
        }
        if (datas.pageCategorie) {
          this.categorie = datas.pageCategorie;
        }
      });
    }
  }
}
