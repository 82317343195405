import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudGenericService} from '../crud-generic.service';
import {
  CreateDemoInterface,
  GetDemoInterface,
  UpdateDemoInterface
} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class DemoService extends CrudGenericService<
  GetDemoInterface,
  CreateDemoInterface,
  UpdateDemoInterface,
  GetDemoInterface
  >{
  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient, 'demo');
  }

  async createLink(id: number): Promise<{ url: string }> {
    return this.httpClient.post<{ url: string }>(`${this.urlPrefix}/${id}/create-link`, {}).toPromise();
  }

  async refresh(id: number): Promise<void> {
    return this.httpClient.put<void>(`${this.urlPrefix}/${id}/refresh-demo`, {}).toPromise();
  }
}
