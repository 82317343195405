<button mat-flat-button color="primary" class="mb-2" (click)="upsert()" [disabled]="dataSource.length >= limit">
  <mat-icon>add_circle_outline</mat-icon>
  {{ 'translation:admin.demo.add' | i18nextEager: { limit: limit, current: dataSource.length } }}
</button>

<div class="mat-elevation-z2">
  <table mat-table class="full-width-table" [dataSource]="dataSource">
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="status">
        <mat-icon [matTooltip]="'translation:admin.demo.status' | i18nextEager: { date: (row.lastUpdateStatusAt | date: 'short') }"
                  [class.invalid]="!row.isOk">
          {{ row.isOk ? 'check_circle': 'error_outline' }}
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.demo.label' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.label}}</td>
    </ng-container>

    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.demo.creator' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.creator}}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.demo.createdAt' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.createdAt | date: 'short' }}</td>
    </ng-container>

    <ng-container matColumnDef="redirectTo">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'translation:admin.demo.redirectTo' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">
        <a [href]="'/admin/demo/redirect-to/' + row.id" target="_blank">
          <button mat-icon-button >
            <mat-icon>open_in_new</mat-icon>
            {{ 'translation:admin.demo.redirectTo-btn' | i18nextEager }}
          </button>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="upsert(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh(row)">
          <mat-icon>refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
