import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApplicationInterface, EditMappingInterface, EditProfileRoleMappingInterface} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  async findAll(): Promise<ApplicationInterface[]> {
    return this.httpClient.get<ApplicationInterface[]>('application').toPromise();
  }

  async updateProfileRoleMapping(id: string, profiles: EditProfileRoleMappingInterface[], rolesToDesync: string[]): Promise<void> {
    const dto: EditMappingInterface = {
      profiles,
      rolesToDesync,
    };

    await this.httpClient.put(`application/${id}/profile-role-mapping`, dto).toPromise();
  }
}
