import {InjectionToken} from '@angular/core';

export interface AppConfig {
  apiBackendUrl: string;
  keycloakUrl: string;
  keycloakRealm: string;
  keycloakClientId: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
