import { Component, OnInit } from '@angular/core';
import {CurrentUserService} from '../../shared/authentication/services/current-user.service';
import {PayloadInterface} from '@d-aim/common';
import {PingService} from '../../shared/services/ping.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  userName = '';

  constructor(
    private readonly userService: CurrentUserService,
    private readonly pingService: PingService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.pingService.ping();

    // this.userName = this.userService.getUsername();
  }
}
