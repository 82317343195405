import {Component, Inject, Input, OnInit} from '@angular/core';
import {
  ApplicationInterface,
  ApplicationRoleAttributes,
  ApplicationRoleInterface,
  EditProfileRoleMappingInterface
} from '@d-aim/common';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import {ApplicationService} from '../../../shared/services/application/application.service';

@Component({
  selector: 'app-edit-profile-roles',
  templateUrl: './edit-profile-roles.component.html',
  styleUrls: ['./edit-profile-roles.component.scss']
})
export class EditProfileRolesComponent implements OnInit {
  @Input()
  client!: ApplicationInterface;
  roles: ApplicationRoleInterface[] = [];
  profiles: ApplicationRoleInterface[] = [];
  profilesWithRoles = new Map<string, Map<string, boolean>>();
  categories: { attributes: ApplicationRoleAttributes, roles: ApplicationRoleInterface[] }[] = [];
  displayedColumns: string[] = [];
  loading = true;
  saving = false;

  hasChanged = false;

  constructor(
    @Inject(I18NEXT_SERVICE)
    public readonly t: ITranslationService,
    private readonly applicationService: ApplicationService,
  ) { }

  ngOnInit(): void {
    this.roles = this.client.roles.filter(e => !e.roles);
    this.profiles = this.client.roles.filter(e => e.roles);
    this.displayedColumns = [];
    this.profilesWithRoles = new Map<string, Map<string, boolean>>();
    const categories = new Map<string | null, { attributes: ApplicationRoleAttributes, roles: ApplicationRoleInterface[] }>();

    for (const profile of this.profiles) {
      const confProfile = new Map<string, boolean>();
      if (profile.roles) {
        for (const role of profile.roles) {
          confProfile.set(role.id, true);
        }
      }
      this.profilesWithRoles.set(profile.id, confProfile);
    }

    for (const role of this.roles) {
      for (const profileId of this.profilesWithRoles.keys()) {
        if (!this.profilesWithRoles.get(profileId)?.has(role.id)) {
          this.profilesWithRoles.get(profileId)?.set(role.id, false);
        }
      }
      if (!categories.has(role.attributes['fr.category.label'] || null)) {
        categories.set(role.attributes['fr.category.label'] || null, { attributes: role.attributes, roles: []});
      }
      categories.get(role.attributes['fr.category.label'] || null)?.roles.push(role);
    }

    this.categories = Array.from(categories.values());
    this.displayedColumns = ['role', ...Array.from(this.profilesWithRoles.keys()), 'synchronize'];
    this.loading = false;
  }

  toggle(profileId: string, roleId: string): void {
    const currentValue = this.profilesWithRoles.get(profileId)?.get(roleId);
    this.profilesWithRoles.get(profileId)?.set(roleId, !currentValue);
  }

  getLangIndex(lang: string, key: string): keyof ApplicationRoleAttributes {
    return lang + key as keyof ApplicationRoleAttributes;
  }

  async saveProfiles(): Promise<void> {
    this.saving = true;
    const profilesToSave: EditProfileRoleMappingInterface[] = [];

    for (const [profileId, roles] of this.profilesWithRoles.entries()) {
      const assignedRoles: string[] = [];
      for (const [roleId, include] of roles.entries()) {
        if (include) {
          assignedRoles.push(roleId);
        }
      }
      profilesToSave.push({
        profileId,
        assignedRoles
      });
    }

    const rolesToDesync: string[] = [];
    for (const cat of this.categories) {
      rolesToDesync.push(...cat.roles.filter(e => e.attributes.overridden).map(e => e.id));
    }

    await this.applicationService.updateProfileRoleMapping(this.client.id, profilesToSave, rolesToDesync);
    this.hasChanged = false;
    this.saving = false;
  }
}
