import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BootstrapService} from './bootstrap.service';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import {PagesModule} from './pages/pages.module';
import {SharedModule} from './shared/shared.module';
import { KeycloakAngularModule } from 'keycloak-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import {PanelWrapperComponent} from './shared/components/formly/panel-wrapper/panel-wrapper.component';
import {MultiSelectComponent} from './shared/components/formly/multi-select/multi-select.component';
import {CkeditorComponent} from './shared/components/formly/ckeditor/ckeditor.component';
import {TabWrapperComponent} from './shared/components/formly/tab-wrapper/tab-wrapper.component';
import {IconSelectorComponent} from './shared/components/formly/icon-selector/icon-selector.component';
import {MultiSelectAutocompleteComponent} from './shared/components/formly/multi-select-autocomplete/multi-select-autocomplete.component';
import {I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeFr);
registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    KeycloakAngularModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserModule,
    CommonModule,
    PagesModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      types: [
        { name: 'multi-select', component: MultiSelectComponent },
        { name: 'multi-select-autocomplete-tag', component: MultiSelectAutocompleteComponent },
        { name: 'ckeditor', component: CkeditorComponent },
        { name: 'icon-select', component: IconSelectorComponent }
      ],
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
        { name: 'tabs', component: TabWrapperComponent }
      ],
    }),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    [
      {
        provide: APP_INITIALIZER,
        multi: true,
        deps: [BootstrapService],
        useFactory: (bootstrapService: BootstrapService) => {
          return async () => await bootstrapService.init();
        }
      },
      {
        provide: LOCALE_ID,
        deps: [I18NEXT_SERVICE],
        useFactory: (i18next: ITranslationService) => i18next.language,
      }
    ],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
