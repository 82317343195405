import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupComponent } from './group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import {GroupRoutingModule} from './group.routing-module';
import { GroupListComponent } from './group-list/group-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {SharedModule} from '../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {I18NextModule} from 'angular-i18next';

@NgModule({
  declarations: [GroupComponent, EditGroupComponent, GroupListComponent],
  imports: [
    CommonModule,
    GroupRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    SharedModule,
    ReactiveFormsModule,
    FormlyModule,
    MatSlideToggleModule,
    I18NextModule,
  ]
})
export class GroupModule { }
