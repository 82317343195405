import { Component, OnInit } from '@angular/core';
import {DemoService} from '../../../../shared/services/admin/demo.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-demo-redirect-to',
  templateUrl: './demo-redirect-to.component.html',
  styleUrls: ['./demo-redirect-to.component.scss']
})
export class DemoRedirectToComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly demoService: DemoService,
  ) { }

  async ngOnInit(): Promise<void> {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    window.location.href = (await this.demoService.createLink(id)).url;
  }
}
