import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudGenericService} from '../crud-generic.service';
import {
  EditFaqInterface,
  GetCategoryNestedInterface,
  GetFaqInterface,
  ReorderFaqInterface,
  ReorderFaqItemInterface,
} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends CrudGenericService<GetFaqInterface, EditFaqInterface, EditFaqInterface, GetFaqInterface>{
  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient, 'faq');
  }

  async reorder(categories: GetCategoryNestedInterface[]): Promise<void> {
    const dto: ReorderFaqInterface = {
      items: [],
    };

    for (const category of categories) {
      const item: ReorderFaqItemInterface = {
        categoryId: category.id || undefined,
        ids: category.faqs.map(el => el.id),
      };
      dto.items.push(item);
    }

    await this.httpClient.put<GetFaqInterface[]>(`${this.urlPrefix}/reorder`, dto).toPromise();
  }
}
