import {Component, Inject, OnInit} from '@angular/core';
import {DemoService} from '../../../../shared/services/admin/demo.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreateDemoInterface, GetDemoInterface, UpdateDemoInterface} from '@d-aim/common';
import {FormBuilder} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {SettingService} from '../../../../shared/services/setting.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-demo-edit',
  templateUrl: './demo-edit.component.html',
  styleUrls: ['./demo-edit.component.scss']
})
export class DemoEditComponent implements OnInit {
  loading = true;
  hasError = false;
  saving = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly demoService: DemoService,
    private readonly settingService: SettingService,
    private readonly dialogRef: MatDialogRef<DemoEditComponent>,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { entity?: GetDemoInterface },
  ) { }

  form = this.fb.group({
    label: [''],
    kind: [''],
    locale: ['']
  });

  model: Record<string, any> = {
    label: '',
    kind: '',
    locale: '',
  };

  fields: FormlyFieldConfig[] = [];

  get id(): number | undefined {
    return this.data?.entity?.id;
  }

  async ngOnInit(): Promise<void> {
    const [DEMO_LOCALE, DEMO_KIND] = await Promise.all([
      this.settingService.findOneByKey('DEMO_LOCALE'),
      this.settingService.findOneByKey('DEMO_KIND'),
    ]);

    const locales: string[] = DEMO_LOCALE.value.split(';');
    const kinds: string[] = DEMO_KIND.value.split(';');

    this.fields = [
      {
        key: 'label',
        type: 'input',
        templateOptions: {
          required: true,
          label: this.translationService.t('translation:admin.demo.label'),
        }
      },
    ];

    if (!this.id) {
      this.fields.push(...[{
        key: 'locale',
        type: 'select',
        templateOptions: {
          required: true,
          label: this.translationService.t('translation:admin.demo.locale'),
          options: locales.map(e => ({ value: e, label: e })),
        }
      },
        {
          key: 'kind',
          type: 'select',
          templateOptions: {
            required: true,
            label: this.translationService.t('translation:admin.demo.kind'),
            options: kinds.map(e => ({ value: e, label: this.translationService.t(`translation:admin.demo.kind-value.${e}`) })),
          }
        }]
      );
    }

    if (this.data?.entity) {
      this.model = {
        label: this.data.entity.label,
        kind: this.data.entity.kind,
        locale: this.data.entity.locale,
      };
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    this.hasError = false;
    this.saving = true;
    try {
      if (this.id) {
        const data: UpdateDemoInterface = {
          label: this.form.get('label')?.value,
        };
        await this.demoService.update(this.id, data);
      } else {
        const data: CreateDemoInterface = {
          kind: this.form.get('kind')?.value,
          label: this.form.get('label')?.value,
          locale: this.form.get('locale')?.value
        };
        await this.demoService.create(data);
      }

      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
    } finally {
      this.saving = false;
    }
  }
}
