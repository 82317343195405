<button mat-icon-button [disabled]="!hasChanged || saving" (click)="saveProfiles()">
  <mat-icon>save</mat-icon>
</button>
<ng-container *ngFor="let category of categories">
  <h3 *ngIf="category.attributes[getLangIndex(t.language, '.category.label')]">
    {{ category.attributes[getLangIndex(t.language, '.category.label')] }}
  </h3>

  <p *ngIf="category.attributes[getLangIndex(t.language, '.category.description')]">
    {{ category.attributes[getLangIndex(t.language, '.category.description')] }}
  </p>

  <div class="mat-elevation-z2" *ngIf="!loading">
    <mat-table [dataSource]="category.roles">
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-expansion-panel *ngIf="element.attributes[getLangIndex(t.language, '.role.description')]">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ element.attributes[getLangIndex(t.language, '.role.label')] || element.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ element.attributes[getLangIndex(t.language, '.role.description')] }}</p>
          </mat-expansion-panel>
          <ng-container *ngIf="!element.attributes[getLangIndex(t.language, '.role.description')]">
            {{ element.attributes[getLangIndex(t.language, '.role.label')] || element.name }}
          </ng-container>
        </mat-cell>
      </ng-container>
      <ng-container *ngFor="let profile of this.profiles">
        <ng-container [matColumnDef]="profile.id">
          <mat-header-cell *matHeaderCellDef>
            {{ profile.attributes[getLangIndex(t.language, '.role.label')] || profile.name }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-slide-toggle (click)="$event.stopPropagation();hasChanged = true; element.attributes.overridden = true"
                              [checked]="profilesWithRoles?.get(profile.id)?.get(element.id)"
                              (change)="toggle(profile.id, element.id)"></mat-slide-toggle>
          </mat-cell>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="synchronize">
        <mat-header-cell *matHeaderCellDef>
          <span [matTooltip]="'translation:profile.synchronize-tooltip' | i18nextEager">
            {{ "translation:profile.synchronize" | i18nextEager }}
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-slide-toggle (click)="$event.stopPropagation();hasChanged = true;"
                            [checked]="!element.attributes.overridden"
                            (change)="element.attributes.overridden = !element.attributes.overridden"></mat-slide-toggle>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</ng-container>
