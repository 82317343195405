import {
  Component,
  ElementRef,
  HostListener, OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {CurrentUserService} from '../../../authentication/services/current-user.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-mon-profile',
  templateUrl: './mon-profile.component.html',
  styleUrls: ['./mon-profile.component.scss'],
})
export class MonProfileComponent implements OnInit {
  openMenu = false;
  userInfo!: { username: string; firstName?: string; lastName?: string };
  profileLink!: string;

  /**
   * Si l'utilisateur a le droit de surcharger ses roles
   */
  userCanOverrideRoles = false;

  @ViewChild('detailProfile')
  detailProfileElement!: ElementRef;

  /**
   * Permet de fermer automatiquement le menu lorsque le click de l'utilisateur
   * est en dehors de la div
   */
  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent): void {
    this.openMenu = this.detailProfileElement.nativeElement.contains(
      event.target,
    );
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly currentUserService: CurrentUserService,
    private readonly userService: UserService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.profileLink = this.userService.getEditProfileUrl();
    this.userInfo = await this.currentUserService.getProfile();
  }

  async disconnect(): Promise<void> {
    await this.currentUserService.logout();
  }
}
