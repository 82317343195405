<div *ngIf="loading" class="loader">
  <app-loader></app-loader>
  <div [innerHTML]="'Loading...' | translate" class="loader__text"></div>
</div>
<div *ngIf="error" class="loader">
  <div [innerHTML]="'@@app.loading.error' | translate" class="alert alert-danger"></div>
</div>
<div *ngIf="!error" id="layout" class="sidebar-collapse">
  <header *ngIf="!loading" class="layout-header">
    <button (click)="toggleSidebar()" mat-icon-button class="layout-header__btn-sidebar">
      <mat-icon>menu</mat-icon>
    </button>
    <app-page-title class="layout-header__title"></app-page-title>
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>language</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="selectLang('en')">English</button>
      <button mat-menu-item (click)="selectLang('fr')">Français</button>
    </mat-menu>
    <app-mon-profile class="layout-header__profile"></app-mon-profile>
  </header>

  <aside *ngIf="!error && !loading" class="layout-sidebar">
    <a class="brand" routerLink="/" routerLinkActive="active">
      <img alt="D-AIM logo small" class="brand__logo--small" src="assets/img/logo-d-aim-sm-tr.png"/>
      <img alt="D-AIM logo large" class="brand__logo--large" src="assets/img/logo-d-aim-lg-tr-2.png"/>
    </a>
    <app-sidenav class="layout-sidebar__sidenav"></app-sidenav>
  </aside>

  <div class="layout-content">
    <router-outlet></router-outlet>
  </div>
</div>
