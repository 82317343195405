import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingInterface} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  async findOneByKey(key: string): Promise<SettingInterface> {
    return this.httpClient.get<SettingInterface>(`/setting/${key}`).toPromise();
  }

  async findAll(): Promise<SettingInterface[]> {
    return this.httpClient.get<SettingInterface[]>(`/setting`).toPromise();
  }
}
