import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import {CategoryComponent} from './category/category.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SharedModule} from '../../../shared/shared.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CategoryEditComponent } from './category/category-edit/category-edit.component';
import {FormlyModule} from '@ngx-formly/core';
import {ReactiveFormsModule} from '@angular/forms';
import { FaqEditComponent } from './faq/faq-edit/faq-edit.component';
import { FaqListComponent } from './faq/faq-list/faq-list.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {I18NextModule} from 'angular-i18next';

@NgModule({
  declarations: [FaqComponent, CategoryComponent, CategoryListComponent, CategoryEditComponent, FaqEditComponent, FaqListComponent],
  exports: [
    CategoryComponent
  ],
  imports: [
    CommonModule,
    MatTreeModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    DragDropModule,
    FormlyModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    I18NextModule
  ]
})
export class FaqModule { }
