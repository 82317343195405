import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertiserComponent } from './advertiser.component';
import { AdvertiserListComponent } from './advertiser-list/advertiser-list.component';
import { AdvertiserEditComponent } from './advertiser-edit/advertiser-edit.component';
import {SharedModule} from '../../../shared/shared.module';
import {MatTableModule} from '@angular/material/table';
import {ReactiveFormsModule} from '@angular/forms';
import {FormlyModule} from '@ngx-formly/core';
import {I18NextModule} from 'angular-i18next';



@NgModule({
  declarations: [AdvertiserComponent, AdvertiserListComponent, AdvertiserEditComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTableModule,
    ReactiveFormsModule,
    FormlyModule,
    I18NextModule
  ]
})
export class AdvertiserModule { }
