import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  EditClientRoleInterface,
  EditUserInterface,
  GetUserInterface,
  GetClientRolesInterface,
} from '@d-aim/common';
import {UserService} from '../../../shared/services/user/user.service';
import {GroupService} from '../../../shared/services/group/group.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import {BootstrapService} from '../../../bootstrap.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  defaultRoles: GetClientRolesInterface[] = [];
  loading = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly groupService: GroupService,
    private readonly dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { user?: GetUserInterface },
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  get userId(): string | undefined {
    return this.data?.user?.id;
  }

  get formRoles(): FormGroup {
    return this.form.get('roles') as FormGroup;
  }

  hasError = false;
  hasConflict = false;
  form = this.fb.group({
    roles: this.fb.group({}),
  });

  model: Record<string, any> = {
    roles: [],
    enabled: true,
    firstName: '',
    lastName: '',
    password: '',
    generateTemporaryPassword: true,
    totp: false,
    username: '',
    email: '',
    locale: 'fr',
  };

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          key: 'username',
          type: 'input',
          templateOptions: {
            attributes: {
              autocomplete: 'off',
            },
            label: this.translationService.t('translation:user.username'),
            required: true,
          }
        },
        {
          className: 'col-6',
          key: 'email',
          type: 'input',
          validators: {
            validation: [Validators.email],
          },
          templateOptions: {
            attributes: {
              autocomplete: 'off',
            },
            label: this.translationService.t('translation:user.email'),
            required: true,
          }
        },
      ]
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          key: 'firstName',
          type: 'input',
          templateOptions: {
            attributes: {
              autocomplete: 'off',
            },
            label: this.translationService.t('translation:user.first-name'),
            required: false,
          }
        },
        {
          className: 'col-6',
          key: 'lastName',
          type: 'input',
          templateOptions: {
            attributes: {
              autocomplete: 'off',
            },
            label: this.translationService.t('translation:user.last-name'),
            required: false,
          }
        },
      ]
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          key: 'password',
          type: 'input',
          templateOptions: {
            attributes: {
              autocomplete: 'off',
            },
            type: 'password',
            label: this.translationService.t('translation:user.password'),
            required: false,
          },
          expressionProperties: {
            'templateOptions.disabled': 'model.generateTemporaryPassword'
          }
        },
        {
          className: 'col-6',
          key: 'generateTemporaryPassword',
          type: 'toggle',
          templateOptions: {
            label: this.translationService.t('translation:user.generate-tmp-password'),
          }
        },
      ],
    },
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          key: 'totp',
          type: 'toggle',
          templateOptions: {
            label: this.translationService.t('translation:user.enable-2fa'),
          }
        },
        {
          className: 'col-6',
          key: 'enabled',
          type: 'toggle',
          templateOptions: {
            label: this.translationService.t('translation:user.enable-account'),
          }
        },
      ],
    },
    {
      key: 'locale',
      type: 'select',
      templateOptions: {
        options: BootstrapService.SUPPORTED_LANGUAGES.map(value => {
          return {
            label: this.translationService.t(`translation:locale.${value}`),
            value,
          };
        }),
        label: this.translationService.t('translation:user.locale'),
        placeholder: this.translationService.t('translation:user.assign-group'),
      }
    },
    {
      key: 'groups',
      type: 'multi-select',
      hide: true, // temporary hidden
      templateOptions: {
        multiple: true,
        placeholder: this.translationService.t('translation:user.assign-group'),
      }
    }
  ];

  async ngOnInit(): Promise<void> {
    const groups = await this.groupService.findAll();
    if (this.data?.user) {
      const [roles, userGroups] = await Promise.all([
        this.userService.userApplicationRoles(this.data.user.id),
        this.userService.userGroups(this.data.user.id),
      ]);

      this.defaultRoles = roles;

      this.model = {
        roles: [],
        enabled: this.data.user.enabled,
        firstName: this.data.user.firstName,
        lastName: this.data.user.lastName,
        password: '',
        generateTemporaryPassword: false,
        totp: this.data.user.totp,
        username: this.data.user.username,
        email: this.data.user.email,
        locale: this.data.user.locale,
        groups: userGroups.map(e => ({ label: e.name, value: e.id })),
      };
    }

    const options = groups.map(e => ({ label: e.name, value: e.id }));

    const fieldGroup = this.fields.find(field => field.key === 'groups');
    if (fieldGroup?.templateOptions) {
      fieldGroup.templateOptions.options = options;
    }

    this.loading = false;
  }

  async save(): Promise<void> {
    const roles: EditClientRoleInterface[] = [];
    for (const [clientId, clientRoles] of Object.entries(this.form.get('roles')?.value)) {
      for (const clientRole of ((clientRoles as { roles: { label: string; value: string}[]}).roles || [])) {
        roles.push({
          clientId,
          name: clientRole.label,
          roleId: clientRole.value,
        });
      }
    }

    const groups = this.form.get('groups')?.value?.map((v: { value: string; }) => v.value) || [];

    const data: EditUserInterface = {
      email: this.form.get('email')?.value,
      enabled: this.form.get('enabled')?.value,
      firstName: this.form.get('firstName')?.value,
      generateTemporaryPassword: this.form.get('generateTemporaryPassword')?.value,
      lastName: this.form.get('lastName')?.value,
      password: this.form.get('password')?.value,
      roles,
      groups,
      totp: this.form.get('totp')?.value,
      username: this.form.get('username')?.value,
      locale: this.form.get('locale')?.value,
    };

    try {
      this.hasConflict = false;
      this.hasError = false;
      if (this.data?.user) {
        await this.userService.updateUser(this.data.user.id, data);
      } else {
        await this.userService.addUser(data);
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
      if (e?.error?.statusCode === 409) {
        this.hasConflict = true;
      }
    }
  }
}
