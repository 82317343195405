import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {APP_CONFIG, AppConfig} from './app.config';
import {KeycloakService} from 'keycloak-angular';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';
import {TranslationsLoaderService} from './shared/services/translations-loader.service';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  public static SUPPORTED_LANGUAGES = ['fr', 'en'];
  private ready: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly keycloakService: KeycloakService,
    @Inject(APP_CONFIG)
    private readonly appConfig: AppConfig,
    @Inject(I18NEXT_SERVICE)
    private readonly i18next: ITranslationService,
    private readonly tLoader: TranslationsLoaderService,
  ) { }

  readyState(): Observable<boolean> {
    return this.ready;
  }

  get isReady(): boolean {
    return this.ready.value;
  }

  async init(): Promise<void>{
    try {
      await this.load();
      this.ready.next(true);
    } catch (e) {
      console.error('Error', e);
      this.ready.error(e);
    }
  }

  async load(): Promise<void> {
    console.log('LOADING APP...');
    await Promise.all([
      this.initAuth(),
      this.loadTranslations(),
    ]);
    const profile = await this.keycloakService.loadUserProfile();
    const locale = (profile as any /* 'attributes' is missing in the interface */)?.attributes?.locale[0];
    await this.i18next.changeLanguage(locale || 'fr');
    console.log('LOADING APP... OK');
  }

  private async initAuth(): Promise<void> {
    const authInit = await this.keycloakService.init({
      config: {
        url: this.appConfig.keycloakUrl,
        realm: this.appConfig.keycloakRealm,
        clientId: this.appConfig.keycloakClientId,
      },
      initOptions: {
        pkceMethod: 'S256',
        onLoad: 'check-sso',
      },
    });

    if (!authInit) {
      throw new Error('Auth failed');
    }
  }

  private async loadTranslations(): Promise<void> {
    const resources = (await Promise.all(BootstrapService.SUPPORTED_LANGUAGES.map(async (lng) => {
      return {
        [lng]: await this.tLoader.getTranslations(lng)
      };
    }))).reduce((p, c) => ({...p, ...c}), {});

    await this.i18next.init({
      resources,
      supportedLngs: BootstrapService.SUPPORTED_LANGUAGES,
      fallbackLng: BootstrapService.SUPPORTED_LANGUAGES[0],
      debug: true,
      returnEmptyString: false,
      interpolation: { escapeValue: false },
      ns: [
        'translation',
        'validation',
        'error'
      ],
    });
  }
}
