import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from '../../shared/authentication/guards/auth.guard';
import {RolesEnum} from '@d-aim/common';
import {FaqComponent} from './faq/faq/faq.component';
import {CategoryComponent} from './faq/category/category.component';
import {AdvertiserComponent} from './advertiser/advertiser.component';
import {EntrypointComponent} from './entrypoint/entrypoint.component';
import {DemoComponent} from './demo/demo.component';
import {DemoRedirectToComponent} from './demo/demo-redirect-to/demo-redirect-to.component';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {
      roles: [RolesEnum.cross_admin]
    },
    children: [
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'category',
        component: CategoryComponent,
      },
      {
        path: 'advertiser',
        component: AdvertiserComponent,
      },
      {
        path: 'entrypoint',
        component: EntrypointComponent,
      },
      {
        path: 'demo',
        children: [
          {
            path: '',
            component: DemoComponent,
          },
          {
            path: 'redirect-to/:id',
            component: DemoRedirectToComponent,
          }
        ]
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
