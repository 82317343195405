import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {HomeModule} from './home/home.module';
import {UserModule} from './user/user.module';
import {GroupModule} from './group/group.module';
import {AdminModule} from './admin/admin.module';
import {ProfileModule} from './profile/profile.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HomeModule,
    UserModule,
    GroupModule,
    AdminModule,
    ProfileModule,
  ]
})
export class PagesModule { }
