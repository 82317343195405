import {HttpClient} from '@angular/common/http';

export class CrudGenericService<FindAllOut, CreateIn, UpdateIn, OneOut> {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly urlPrefix: string,
  ) { }

  findAll(): Promise<FindAllOut[]> {
    return this.httpClient.get<FindAllOut[]>(this.urlPrefix).toPromise();
  }

  create(dto: CreateIn): Promise<OneOut> {
    return this.httpClient.post<OneOut>(this.urlPrefix, dto).toPromise();
  }

  update(id: number, dto: UpdateIn): Promise<OneOut> {
    return this.httpClient.put<OneOut>(`${this.urlPrefix}/${id}`, dto).toPromise();
  }

  async deleteOne(id: number): Promise<void> {
    await this.httpClient.delete(`${this.urlPrefix}/${id}`).toPromise();
  }
}
