import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CrudGenericService} from '../crud-generic.service';
import {EditAdvertiserInterface, GetAdvertiserInterface} from '@d-aim/common';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService extends CrudGenericService<
  GetAdvertiserInterface,
  EditAdvertiserInterface,
  EditAdvertiserInterface,
  GetAdvertiserInterface
>{
  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient, 'advertiser');
  }

  async refreshSecret(id: number): Promise<GetAdvertiserInterface> {
    return this.httpClient.put<GetAdvertiserInterface>(`${this.urlPrefix}/${id}/refresh-secret`, {}).toPromise();
  }
}
