import {Component, Inject, Input, OnInit} from '@angular/core';
import {ApplicationService} from '../../../services/application/application.service';
import {ApplicationInterface, GetClientRolesInterface} from '@d-aim/common';
import {FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {
  @Input()
  form!: FormGroup;

  @Input()
  defaultRoles: GetClientRolesInterface[] = [];

  applications: ApplicationInterface[] = [];
  loading = true;
  model = {};
  fields: FormlyFieldConfig[] = [];

  constructor(
    private readonly applicationService: ApplicationService,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.applications = await this.applicationService.findAll();

    for (const application of this.applications) {
      if (application.roles.length === 0) {
        continue;
      }

      const options = application.roles.filter(r => r.roles).map(r => ({ label: r.name, value: r.id }));
      const defaultValue: { label: string; value: string; }[] = [];
      if (this.defaultRoles.length) {
        const appRoles = this.defaultRoles.find(e => e.id === application.id);
        if (appRoles) {
          appRoles.mappings.forEach(role => {
            return defaultValue.push({ label: role.name, value: role.id });
          });
        }
      }

      this.fields.push({
        key: application.id,
        wrappers: ['panel'],
        templateOptions: { label: application.name },
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            key: 'roles',
            type: 'multi-select',
            defaultValue,
            templateOptions: {
              multiple: true,
              placeholder: this.translationService.t('translation:edit-role.placeholder'),
              options,
            }
          }
        ],
      });
    }
    this.loading = false;
  }

}
