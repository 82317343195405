import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FaqModule} from './faq/faq.module';
import {AdminRoutingModule} from './admin.routing-module';
import {AdvertiserModule} from './advertiser/advertiser.module';
import {EntrypointModule} from './entrypoint/entrypoint.module';
import {DemoModule} from './demo/demo.module';

@NgModule({
  declarations: [],
  imports: [
    FaqModule,
    AdvertiserModule,
    CommonModule,
    AdminRoutingModule,
    EntrypointModule,
    DemoModule,
  ]
})
export class AdminModule { }
