import {Component, Inject, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {CurrentUserService} from '../../../authentication/services/current-user.service';
import {RolesEnum} from '@d-aim/common';
import {Config} from '../../../../../main';
import {APP_CONFIG} from '../../../../app.config';

export interface SideNavCategory {
  type: 'Category';
  name: string;
  displayCount?: number;
  icon: string;
  text: string;
  links: SideNavLink[];
}

export interface SideNavLink {
  type: 'Link';
  display: boolean;
  icon: string;
  link: string;
  text: string;
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  navOpen = '';
  links: (SideNavCategory | SideNavLink)[] = [];

  constructor(
    public readonly dialog: MatDialog,
    private readonly currentUserService: CurrentUserService,
    @Inject(APP_CONFIG)
    private readonly config: Config,
  ) {}

  ngOnInit(): void {
    this.links = [
      { type: 'Link', display: true, icon: 'home', link: '/',  text: 'translation:menu.home'},
      { type: 'Link', display: this.currentUserService.hasRole(RolesEnum.admin_user), icon: 'people_alt', link: '/user',  text: 'translation:menu.user'},
      { type: 'Link', display: this.currentUserService.hasRole(RolesEnum.admin_profile), icon: 'groups', link: '/profile',  text: 'translation:menu.profile'},
      // temporary hidden
      { type: 'Link', display: false && this.currentUserService.hasRole(RolesEnum.admin_group), icon: 'groups', link: '/group',  text: 'translation:menu.group'},
    ];

    if (this.config.featureAdmin && this.currentUserService.hasRole(RolesEnum.cross_admin)) {
      this.links.push(
        {
          type: 'Link',
          display: true,
          icon: 'sports_martial_arts',
          link: '/admin/demo',
          text: 'translation:menu.demo',
        },
      );
      this.links.push(
        {
          type: 'Link',
          display: true,
          icon: 'manage_accounts',
          link: '/admin/advertiser',
          text: 'translation:menu.advertiser',
        },
      );
      this.links.push(
        {
          type: 'Category',
          name: 'translation:menu.faq',
          icon: 'quiz',
          text: 'FAQ',
          links: [
            {
              type: 'Link',
              display: true,
              icon: 'category',
              link: '/admin/category',
              text: 'translation:menu.category'
            },
            {
              type: 'Link',
              display: true,
              icon: 'question_answer',
              link: '/admin/faq',
              text: 'translation:menu.qa'
            },
            {
              type: 'Link',
              display: true,
              icon: 'gps_fixed',
              link: '/admin/entrypoint',
              text: 'translation:menu.entrypoint'
            },
          ]
        },
      );
    }


    for (const category of this.links) {
      if (category.type !== 'Category') {
        continue;
      }
      category.displayCount = category.links.filter(e => e.display).length;
    }
  }

  /**
   * Enregistre le nom du menu à ouvrir
   * Supprime le nom enregistré si c'est le même que celui du menu à ouvrir
   * @param navName Nom du menu à ouvrir
   */
  openNav(navName: string): void {
    if (this.navOpen === navName) {
      this.closeNav();
    } else {
      this.navOpen = navName;
    }
  }

  /**
   * Supprime le nom du menu ouvert pour ne pas le garder ouvert lors du changement de page
   */
  closeNav(): void {
    this.navOpen = '';
  }
}
