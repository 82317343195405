import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FieldType} from '@ngx-formly/core';

@Component({
  selector: 'app-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrls: ['./multi-select-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectAutocompleteComponent extends FieldType implements OnInit {
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  itemCtrl = new FormControl();
  filteredItems!: Observable<string[]>;

  get selectedItemsFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  @ViewChild('input')
  input!: ElementRef<HTMLInputElement>;

  get allItems(): string [] {
    return this.to.options as string[] || [];
  }

  async add(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedItemsFormControl.value.push(value);
    }

    // Clear the input value
    this.input.nativeElement.value = '';

    this.itemCtrl.setValue('');
  }

  async remove(element: string): Promise<void> {
    const index = this.selectedItemsFormControl.value.findIndex((el: string) => el === element);

    if (index >= 0) {
      this.selectedItemsFormControl.value.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItemsFormControl.value.push(event.option.value);
    this.input.nativeElement.value = '';
    this.itemCtrl.setValue('');
  }

  private filter(value: string): string[] {
    if (!value) {
      return this.allItems;
    }

    const filterValue = value.toLowerCase();

    return this.allItems.filter(item => item.toLowerCase().includes(filterValue));
  }

  async ngOnInit(): Promise<void> {
    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map((search: string | null) => search ? this.filter(search) : this.allItems.slice()),
      map((items: string[]) => {
        return items.filter(item => !this.selectedItemsFormControl.value.includes(item));
      })
    );
  }
}
