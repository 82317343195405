import {Component, ViewEncapsulation} from '@angular/core';
import {FieldWrapper, FormlyFieldConfig} from '@ngx-formly/core';

@Component({
  selector: 'app-tab-wrapper',
  templateUrl: './tab-wrapper.component.html',
  styleUrls: ['./tab-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabWrapperComponent extends FieldWrapper {
  getFieldGroup(): FormlyFieldConfig[] {
    return this.field.fieldGroup as FormlyFieldConfig[];
  }
}
