import {Component, Inject, OnInit} from '@angular/core';
import {ApplicationService} from '../../shared/services/application/application.service';
import {ApplicationInterface} from '@d-aim/common';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  apps: ApplicationInterface[] = [];
  loading = true;

  constructor(
    private readonly applicationService: ApplicationService,
    @Inject(I18NEXT_SERVICE)
    public readonly translationService: ITranslationService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.apps = await this.applicationService.findAll();
    this.loading = false;
  }

}
