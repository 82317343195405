import { Component, Input, OnInit } from '@angular/core';
import ColorHash from 'color-hash-ts';

export interface BadgeUserInterface { firstName?: string; lastName?: string; username: string; color?: string; }
@Component({
  selector: 'app-badge-user',
  templateUrl: './badge-user.component.html',
  styleUrls: ['./badge-user.component.scss'],
})
export class BadgeUserComponent implements OnInit {
  @Input()
  element: BadgeUserInterface = { username: '' };

  @Input()
  showTooltip = true;

  @Input()
  size = 40;

  falseRandomColor: Array<string> = [
    '#8575C6',
    '#5580CE',
    '#33A9DD',
    '#30C606',
    '#33CC66',
    '#FC8D08',
    '#EF6E16',
    '#EF3314',
    '#F41952',
  ];

  MAX_COLOR_NUMBER = 9;

  data = {
    name: '',
    initial: '',
    color: '',
    batch: false,
  };

  ngOnInit(): void {
    this.badgeUser(this.element);
  }

  /**
   * Ajout au transforme data du component audit pour gerer les données du badge user
   * Ce module est à utiliser pour le Badge User
   *
   * Pseudo random des couleurs à partir du prénom.
   */
  private badgeUser(element: BadgeUserInterface): void {
    this.data.name = '';
    this.data.initial = '';
    if (element.firstName && element.lastName) {
      // nom complet
      this.data.name = element.firstName + ' ' + element.lastName;
      // récupération des initiales
      this.data.initial =
        element.firstName[0].toUpperCase() + element.lastName[0].toUpperCase();
      // récupération de la couleur généré par le full name
      this.defineUserColor(element.color);
    } else if (element.username) {
      this.data.initial = (
        (element.username[0] || '') + (element.username[1] || '')
      ).toUpperCase();
      this.data.name = element.username;
      this.defineUserColor(element.color);
    }
  }

  defineUserColor(elementColor?: string): void {
    const color = new ColorHash();
    this.data.color =
      elementColor === undefined
        ? this.falseRandom(color.hex(this.data.initial))
        : elementColor;
  }

  /**
   * Génere une couleur parmi MAX_COLOR_NUMBER à partir d'une couleur aléatoire.
   *
   * @param color string hexa de couleur
   * @return color string hexa de couleur
   */
  private falseRandom(color: string): string {
    // retrait du diese
    color = color.substring(1);
    let num: number = parseInt(color, 16);
    num = parseInt(num.toString(), 10);
    let i = 0;
    const dizColor = 1677722;
    while (i < this.MAX_COLOR_NUMBER) {
      if (num < (i + 1) * dizColor) {
        break;
      }
      i++;
    }
    // à partir du number représentant l'hexa attribut une case à l'array contenant les MAX_COLOR_NUMBER couleurs
    color = this.falseRandomColor[i];
    return color;
  }
}
