import { Injectable } from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import * as Keycloak_ from 'keycloak-js';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  constructor(
    private readonly keycloakService: KeycloakService,
  ) { }

  async logout(): Promise<void> {
    return this.keycloakService.logout();
  }

  async getProfile(): Promise<{ username: string; firstName?: string; lastName?: string }> {
    return (await this.keycloakService.loadUserProfile()) as { username: string; firstName?: string; lastName?: string };
  }

  getUsername(): string {
    return this.keycloakService.getUsername();
  }

  getRoles(): string[]  {
    return this.keycloakService.getUserRoles();
  }

  hasRole(role: string): boolean {
     return this.getRoles().includes(role);
  }

  /**
   * Refresh session if it expires in less than 120 seconds
   */
  async refreshSession(): Promise<void> {
    await this.keycloakService.updateToken(120);
  }

  getAccessToken(): string {
    return this.keycloakService.getKeycloakInstance().token as string;
  }

  getAccessTokenForHeader(): string {
    return `Bearer ${this.getAccessToken()}`;
  }
}
