import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './components/not-found/not-found.component';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './authentication/interceptors/auth.interceptor';
import {LoaderComponent} from './components/layouts/loader/loader.component';
import {PageTitleComponent} from './components/layouts/page-title/page-title.component';
import {MonProfileComponent} from './components/layouts/mon-profile/mon-profile.component';
import {SidenavComponent} from './components/layouts/sidenav/sidenav.component';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {BadgeUserComponent} from './components/layouts/badge-user/badge-user.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { PanelWrapperComponent } from './components/formly/panel-wrapper/panel-wrapper.component';
import { MultiSelectComponent } from './components/formly/multi-select/multi-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {EditRoleComponent} from './components/role/edit-role/edit-role.component';
import {FormlyModule} from '@ngx-formly/core';
import { CkeditorComponent } from './components/formly/ckeditor/ckeditor.component';
import {CKEditorModule} from 'ckeditor4-angular';
import { TabWrapperComponent } from './components/formly/tab-wrapper/tab-wrapper.component';
import {MatTabsModule} from '@angular/material/tabs';
import { IconSelectorComponent } from './components/formly/icon-selector/icon-selector.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import { MultiSelectAutocompleteComponent } from './components/formly/multi-select-autocomplete/multi-select-autocomplete.component';
import {MatChipsModule} from '@angular/material/chips';
import {I18NextModule} from 'angular-i18next';

@NgModule({
  declarations: [
    NotFoundComponent,
    LoaderComponent,
    PageTitleComponent,
    MonProfileComponent,
    SidenavComponent,
    BadgeUserComponent,
    ConfirmDialogComponent,
    PanelWrapperComponent,
    MultiSelectComponent,
    EditRoleComponent,
    CkeditorComponent,
    TabWrapperComponent,
    IconSelectorComponent,
    MultiSelectAutocompleteComponent,
  ],
  providers: [
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
    ],
  ],
  exports: [
    MultiSelectAutocompleteComponent,
    IconSelectorComponent,
    TabWrapperComponent,
    CkeditorComponent,
    EditRoleComponent,
    MultiSelectComponent,
    PanelWrapperComponent,
    LoaderComponent,
    PageTitleComponent,
    MonProfileComponent,
    SidenavComponent,
    BadgeUserComponent,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    BrowserModule,
    CommonModule,
  ],
  imports: [
    MatInputModule,
    CKEditorModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormlyModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    I18NextModule,
  ],
})
export class SharedModule { }
