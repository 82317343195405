<mat-form-field appearance="fill">
  <mat-label>{{ to.label }}</mat-label>
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let element of selectedItemsFormControl.value"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(element)">
      {{ element }}
      <button matChipRemove *ngIf="removable">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      [placeholder]="to.placeholder || ''"
      #input
      [formControl]="itemCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let element of filteredItems | async" [value]="element">
      {{ element }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
