"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolesEnum = void 0;
var RolesEnum;
(function (RolesEnum) {
    RolesEnum["admin_user"] = "admin_user";
    RolesEnum["admin_group"] = "admin_group";
    RolesEnum["cross_admin"] = "cross_admin";
    RolesEnum["admin_profile"] = "admin_profile";
})(RolesEnum = exports.RolesEnum || (exports.RolesEnum = {}));
