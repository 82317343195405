<nav>
  <ul class="nav-list" id="nav-list">
    <ng-container *ngFor="let link of links">

      <li class="nav-list__item" *ngIf="link.type === 'Link' && link.display">
        <a (click)="closeNav()" class="nav-list__link" [routerLink]="link.link" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="nav-list__link--active">
          <mat-icon class="nav-list__link-icon">{{ link.icon }}</mat-icon>
          <span class="nav-list__link-text">{{ link.text | i18nextEager }}</span>
        </a>
      </li>

      <li
        #paramActive="routerLinkActive"
        *ngIf="link.type === 'Category' && link.displayCount"
        [class.nav-list__item--opened]="navOpen === link.name || navOpen === '' && paramActive.isActive"
        class="nav-list__item"
        routerLinkActive
      >
        <span (click)="openNav(link.name)" [class.nav-list__link--active]="paramActive.isActive" class="nav-list__link">
          <mat-icon class="nav-list__link-icon">{{ link.icon }}</mat-icon>
          <span class="nav-list__link-text">{{ link.text | i18nextEager }}</span>
          <mat-icon class="nav-list__link-chevron">chevron_left</mat-icon>
        </span>
        <ul [ngClass]="'nav-list--' + link.displayCount + 'items'" class="nav-list">
          <ng-container *ngFor="let link of link.links">
            <li *ngIf="link.display" class="nav-list__item">
              <a
                (click)="closeNav()"
                [routerLink]="link.link | i18nextEager"
                class="nav-list__link"
                routerLinkActive="nav-list__link--active"
              >
                <mat-icon class="nav-list__link-icon">{{ link.icon }}</mat-icon>
                <span class="nav-list__link-text">
                  {{ link.text | i18nextEager }}
                </span>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</nav>
<!-- /.sidebar-menu -->
