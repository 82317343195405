import { Component } from '@angular/core';
import {FieldType} from '@ngx-formly/core';
import {FormControl} from '@angular/forms';
import {CKEditor4} from 'ckeditor4-angular';
import {CkeditorService} from './ckeditor.service';


@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss']
})
export class CkeditorComponent extends FieldType {
  constructor(private readonly ckeditorService: CkeditorService) {
    super();
  }

  onNamespaceLoaded(event: CKEditor4.EventInfo): void {
    // drag'n'drop file upload
    // this.ckeditorService.handleCkeditorInit(event);
  }

  onFileUploadRequest(event: CKEditor4.EventInfo): void {
    // drag'n'drop file upload
    // this.ckeditorService.handleCkeditorFileUpload(event);
  }


  get ckeditorFormControl(): FormControl {
    return this.formControl as FormControl;
  }
}
