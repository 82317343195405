import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {EditClientRoleInterface, EditGroupInterface, GetClientRolesInterface, GetGroupInterface} from '@d-aim/common';
import {GroupService} from '../../../shared/services/group/group.service';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {
  defaultRoles: GetClientRolesInterface[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly groupService: GroupService,
    private readonly dialogRef: MatDialogRef<EditGroupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: { group?: GetGroupInterface },
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) { }

  get id(): string | undefined {
    return this.data?.group?.id;
  }

  get formRoles(): FormGroup {
    return this.form.get('roles') as FormGroup;
  }

  hasError = false;
  hasConflict = false;
  form = this.fb.group({
    roles: this.fb.group({}),
  });

  model: Record<string, any> = {
    roles: [],
    name: '',
  };

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12',
          key: 'name',
          type: 'input',
          templateOptions: {
            attributes: {
              autocomplete: 'off',
            },
            label: this.translationService.t('translation:group.name'),
            required: true,
          }
        },
      ]
    },
  ];

  async ngOnInit(): Promise<void> {
    if (this.data?.group) {
      this.defaultRoles = await this.groupService.groupApplicationRoles(this.data.group.id);
      this.model = {
        name: this.data.group.name,
      };
    }
  }

  async save(): Promise<void> {
    const roles: EditClientRoleInterface[] = [];
    for (const [clientId, clientRoles] of Object.entries(this.form.get('roles')?.value)) {
      for (const clientRole of ((clientRoles as { roles: { label: string; value: string}[]}).roles || [])) {
        roles.push({
          clientId,
          name: clientRole.label,
          roleId: clientRole.value,
        });
      }
    }

    const data: EditGroupInterface = {
      name: this.form.get('name')?.value,
      roles,
    };

    try {
      this.hasConflict = false;
      this.hasError = false;
      if (this.data?.group) {
        await this.groupService.updateGroup(this.data.group.id, data);
      } else {
        await this.groupService.addGroup(data);
      }
      this.dialogRef.close(true);
    } catch (e) {
      this.hasError = true;
      if (e?.error?.statusCode === 409) {
        this.hasConflict = true;
      }
    }
  }
}
