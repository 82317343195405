<div class="header">
  <h2 mat-dialog-title>
    <ng-container *ngIf="!userId">
      {{ 'translation:user.create-user' | i18nextEager }}
    </ng-container>
    <ng-container *ngIf="userId">
      {{ 'translation:user.update-user' | i18nextEager }}
    </ng-container>
  </h2>
  <div class="flex-space"></div>
  <button mat-icon-button [mat-dialog-close] class="button-close">
    <mat-icon>close</mat-icon>
  </button>
</div>
<ng-container *ngIf="!loading">
  <mat-dialog-content>
  <div class="alert alert-danger" *ngIf="hasError">
    <span *ngIf="hasConflict">
      {{ 'error.email-username-already-used' | i18nextEager }}
    </span>
    <span *ngIf="!hasConflict">
      {{ 'error:generic' | i18nextEager }}
    </span>
  </div>
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>
  <app-edit-role [form]="formRoles" [defaultRoles]="defaultRoles"></app-edit-role>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">
      {{ 'translation:action.cancel' | i18nextEager }}
    </button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="form.invalid">
      {{ 'translation:action.save' | i18nextEager }}
    </button>
  </mat-dialog-actions>
</ng-container>
<app-loader *ngIf="loading"></app-loader>
