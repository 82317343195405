<mat-form-field appearance="fill" class="multiselect">
  <mat-label *ngIf="to.label">{{ to.label }}</mat-label>
  <mat-select [formControl]="multiSelectFormControl"
              [multiple]="to.multiple"
              [placeholder]="to.placeholder ? to.placeholder : ''"
              [compareWith]="compareWith">
    <mat-select-trigger>
      <span *ngIf="formControl.value?.length > 0 && formControl.value?.length < 8">
        {{ values }}
      </span>
      <span *ngIf="formControl.value?.length > 8">
        (+{{formControl.value.length - 1}} {{formControl.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>

    <ng-container *ngIf="fieldGroupOptions !== null">
      <mat-optgroup *ngFor="let group of fieldGroupOptions" [label]="group.label" [disabled]="group.disabled">
        <mat-option *ngFor="let option of group.options" [value]="option">
          {{option.label}}
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-container *ngIf="fieldGroupOptions === null">
      <mat-option *ngFor="let option of fieldOptions" [value]="option">
        {{ option.label }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
