import {Component, Inject, OnInit} from '@angular/core';
import {GetEntrypointInterface} from '@d-aim/common';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {EntrypointService} from '../../../../shared/services/admin/entrypoint.service';
import {EntrypointEditComponent} from '../entrypoint-edit/entrypoint-edit.component';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-entrypoint-list',
  templateUrl: './entrypoint-list.component.html',
  styleUrls: ['./entrypoint-list.component.scss']
})
export class EntrypointListComponent implements OnInit {
  dataSource: GetEntrypointInterface[] = [];
  displayedColumns = ['id', 'label', 'code', 'actions'];

  constructor(
    private readonly dialog: MatDialog,
    private readonly entrypointService: EntrypointService,
    @Inject(I18NEXT_SERVICE)
    private readonly translationService: ITranslationService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.fetchData();
  }
  async fetchData(): Promise<void> {
    this.dataSource = await this.entrypointService.findAll();
  }

  async create(entity?: GetEntrypointInterface): Promise<void> {
    const dialogRef = this.dialog.open(EntrypointEditComponent, { width: '720px', data: { entity } });
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.fetchData();
      }
    });
  }

  async update(entity: GetEntrypointInterface): Promise<void> {
    await this.create(entity);
  }

  async delete(entity: GetEntrypointInterface): Promise<void> {
    const dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      {
        data: { message: this.translationService.t('translation:admin.entrypoint:delete', { name: entity.label }) }
      }
    );
    dialogRef.afterClosed().subscribe(async v => {
      if (v) {
        await this.entrypointService.deleteOne(entity.id);
        await this.fetchData();
      }
    });
  }
}
