<button mat-flat-button color="primary" class="mb-2" (click)="createNewGroup()">
  <mat-icon>group_add</mat-icon>
  {{ 'translation:group.add-new-group' | i18nextEager }}
</button>

<div class="mat-elevation-z2">
  <table mat-table class="full-width-table" matSort aria-label="Elements">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'translation:group.name' | i18nextEager }}
      </th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="updateGroup(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteGroup(row)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource?.data?.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>
</div>
